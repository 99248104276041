import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import InvestorCard from '../../Components/InvestorCard/InvestorCard';

const InvestorCardTwig = ({
  preFetchedInvestors,
  locale,
  currencyCode,
  hasOverflow
}) => {
  // convert preFetchedInvestors string to JSON
  const preRenderedInvestors = JSON.parse(preFetchedInvestors);

  const [investors, setInvestors] = useState([]);

  // eslint-disable-next-line consistent-return
  const renderInvestors = () => {
    if (preRenderedInvestors?.length) {
      return setInvestors(preRenderedInvestors);
    }
  };

  useEffect(() => {
    if (!investors.length) {
      renderInvestors();
    }
  }, [investors]);

  return (
    investors?.length && (
      <div
        className={`${
          hasOverflow
            ? 'flex md:flex-row flex-col md:items-stretch items-center md:overflow-auto scrollbar-hide'
            : 'grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 md:max-w-screen-xl mx-auto'
        } w-full`}
      >
        {investors.map((investor) => {
          return (
            <InvestorCard
              key={investor.detailsUrl}
              investor={investor}
              locale={locale}
              isLoggedIn={false}
              currencyCode={currencyCode}
            />
          );
        })}
      </div>
    )
  );
};

export default InvestorCardTwig;

InvestorCardTwig.propTypes = {
  preFetchedInvestors: PropTypes.string,
  locale: PropTypes.string,
  currencyCode: PropTypes.string,
  hasOverflow: PropTypes.bool
};

InvestorCardTwig.defaultProps = {
  preFetchedInvestors: null,
  locale: null,
  currencyCode: null,
  hasOverflow: false
};
