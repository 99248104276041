/* eslint-disable indent */
/* eslint-disable no-undef */
export const displayToast = async (message, type) => {
  const errorElem = document?.createElement('div');

  if (errorElem) {
    if (type === 'toast') {
      errorElem.className = `fixed bottom-24 rounded-lg text-center text-sm bg-white py-2 px-3 text-gray-800 font-semibold left-1/2 transform -translate-x-1/2 shadow-5xl`;
    } else {
      errorElem.className = `w-full fixed bottom-0 inset-x-0 text-center text-base ${
        type === 'error'
          ? 'bg-red-500'
          : type === 'success'
          ? 'bg-green-500'
          : type === 'warning'
          ? 'bg-yellow-500'
          : ''
      } py-5 text-white`;
    }
    errorElem.style.zIndex = '1111';
    errorElem.innerHTML = message;
  }

  document?.getElementsByTagName('body')[0].appendChild(errorElem);

  await new Promise((resolve) => {
    setTimeout(resolve, 5000);
  });

  errorElem?.remove();
};

export default displayToast;
