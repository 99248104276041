import React, { useRef, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const StatesSection = ({ businessProposalUri, statesString }) => {
  const { t } = useTranslation();

  const states = JSON.parse(statesString);

  const wrapper = useRef();
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <section className="pt-20 md:pb-20 pb-16 w-full bg-white">
      <div className="w-full md:px-5 px-3 max-w-screen-xl mx-auto">
        <div className="w-full relative flex flex-col gap-8">
          <div className="w-full flex flex-col items-center text-center gap-2">
            <h2 className="text-gray-800 md:text-4xl md:leading-11 text-3xl leading-8 font-semibold">
              {t('homepage.states.title')}
            </h2>
            <p className="text-gray-700 text-xl leading-7.85">
              {t('homepage.states.description')}
            </p>
          </div>
          <div
            ref={wrapper}
            className="w-full grid lg:grid-cols-7 md:grid-cols-5 grid-cols-2 gap-4"
          >
            {states.map((state, index) => {
              return (
                <a
                  key={state.locationId}
                  href={businessProposalUri.replace(
                    '_COUNTRY_',
                    state.slugifiedName
                  )}
                  className="group"
                >
                  <div
                    className={`w-full bg-gray-50 group-hover:bg-gray-100 rounded-2xl flex flex-col items-center justify-center gap-1 text-center transform transition-all duration-500 ${
                      !isExpanded && index > 13
                        ? 'opacity-0 h-0'
                        : 'h-36 opacity-100'
                    }`}
                  >
                    <img
                      src={`/images/svg/us-states/${state.name
                        .toLowerCase()
                        .replaceAll(' ', '-')}.svg`}
                      alt={state.name}
                      className="h-20 w-auto"
                    />
                    <span className="text-sm leading-6 text-gray-700 font-semibold whitespace-nowrap">
                      {state.name}
                    </span>
                  </div>
                </a>
              );
            })}
          </div>
          {!isExpanded && (
            <button
              type="button"
              onClick={() => {
                return setIsExpanded(true);
              }}
              className="absolute md:bottom-0 bottom-40 left-1/2 transform -translate-x-1/2 text-blue-700 underline text-center text-xl leading-6"
            >
              {t('homepage.states.viewMore')}
            </button>
          )}
        </div>
      </div>
    </section>
  );
};

export default StatesSection;

StatesSection.propTypes = {
  businessProposalUri: PropTypes.string.isRequired,
  statesString: PropTypes.string.isRequired
};
