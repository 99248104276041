import axios from 'axios';
import { displayToast } from './Toast';

const multipartForm = 'multipart/form-data';

const apiService = async ({
  url,
  method,
  data,
  onSuccess,
  onError,
  onFinally,
  contentType = 'application/json'
}) => {
  // Generic API call function for unauthenticated endpoints
  const response = await axios({
    method,
    baseURL: '/',
    url,
    params: method === 'GET' ? data || {} : {},
    data: method !== 'GET' ? data || {} : {},
    headers: {
      'Content-Type': contentType,
      Accept: 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    },
    timeout: 90000
  })
    .then((receivedResponse) => {
      onSuccess?.();
      return receivedResponse;
    })
    .catch((error) => {
      return onError ? onError(error) : displayToast(error.message, 'error');
    })
    .finally(() => {
      onFinally?.();
    });
  return response?.data ?? null;
};

export const deleteProposal = (url) => {
  return apiService({
    url,
    method: 'DELETE'
  });
};

export const shortlistProposal = (url) => {
  return apiService({
    url,
    method: 'POST'
  });
};

export const unShortlistProposal = (url) => {
  return apiService({
    url,
    method: 'DELETE'
  });
};

export const fetchConnectionsReport = (url, params) => {
  return apiService({
    url,
    method: 'POST',
    data: params
  });
};

export const fetchConnectionSummary = (url) => {
  return apiService({
    url,
    method: 'GET'
  });
};

export const fetchDashoardData = (url) => {
  return apiService({
    url,
    method: 'GET'
  });
};

export const fetchFundingTypes = (url) => {
  return apiService({
    url,
    method: 'GET'
  });
};

export const fetchIndustries = (url) => {
  return apiService({
    url,
    method: 'GET'
  });
};

export const fetchInvestors = (url, params) => {
  return apiService({
    url,
    method: 'GET',
    data: params
  });
};

export const fetchLanguages = (url, params) => {
  return apiService({
    url,
    method: 'GET',
    data: params
  });
};

export const fetchMessageById = (url) => {
  return apiService({
    url,
    method: 'GET'
  });
};

export const fetchMessages = (url, params) => {
  return apiService({
    url,
    method: 'GET',
    data: params
  });
};

export const fetchNewsfeed = (url) => {
  return apiService({
    url,
    method: 'GET'
  });
};

export const fetchNotifications = (url, params) => {
  return apiService({
    url,
    method: 'GET',
    data: params
  });
};

export const fetchPlaces = (url, params) => {
  return apiService({
    url,
    method: 'GET',
    data: params
  });
};

export const fetchProposals = (url, params) => {
  return apiService({
    url,
    method: 'GET',
    data: params
  });
};

export const fetchQuickLinks = (url) => {
  return apiService({
    url,
    method: 'GET'
  });
};

export const fetchStages = (url) => {
  return apiService({
    url,
    method: 'GET'
  });
};

export const fetchTaxReliefs = (url) => {
  return apiService({
    url,
    method: 'GET'
  });
};

export const fetchUsers = (url, params) => {
  return apiService({
    url,
    method: 'POST',
    data: params
  });
};

export const messageAction = (url) => {
  return apiService({
    url,
    method: 'POST'
  });
};

export const nudgeInvestor = (url, params) => {
  return apiService({
    url,
    method: 'POST',
    data: params
  });
};

export const postNewsfeed = (url, params) => {
  return apiService({
    url,
    method: 'POST',
    data: params,
    contentType: multipartForm
  });
};

export const removeNewsfeedImage = (url, params) => {
  return apiService({
    url,
    method: 'DELETE',
    data: params,
    contentType: multipartForm
  });
};

export const sendGetMoreRequestAboutBrokingProposal = (url, params) => {
  return apiService({
    url,
    method: 'POST',
    data: params,
    contentType: multipartForm
  });
};

export const sendMessage = (url, params) => {
  return apiService({
    url,
    method: 'POST',
    data: params,
    contentType: multipartForm
  });
};

export const shortlistInvestor = (url) => {
  return apiService({
    url,
    method: 'POST'
  });
};

export const unShortlistInvestor = (url) => {
  return apiService({
    url,
    method: 'DELETE'
  });
};

export const postBenefitOffer = (url, params) => {
  return apiService({
    url,
    method: 'POST',
    data: params,
    contentType: multipartForm
  });
};

export const postCaseStudy = (url, params) => {
  return apiService({
    url,
    method: 'POST',
    data: params,
    contentType: multipartForm
  });
};

export const removeBenefitOfferImage = (url, params) => {
  return apiService({
    url,
    method: 'DELETE',
    data: params
  });
};

export const submitCancellationSurvey = (
  url,
  params,
  onSuccess,
  onError,
  onFinally
) => {
  return apiService({
    url,
    method: 'POST',
    data: params,
    onSuccess,
    onError,
    onFinally
  });
};
