import React from 'react';
import * as PropTypes from 'prop-types';

const IndustryList = ({ industries }) => {
  return (
    <div className="w-full grid lg:grid-cols-7 md:grid-cols-5 grid-cols-2 gap-4">
      {industries.map((industry) => {
        return (
          <a
            key={industry?.url}
            href={industry?.url}
            className="group inline-flex flex-col items-center justify-start text-center gap-2"
          >
            <div className="w-full bg-gray-50 group-hover:bg-gray-100 rounded-2xl inline-flex items-center justify-center transform transition-all duration-500 h-36">
              <img
                src={industry?.icon}
                alt={industry?.title}
                className="w-12 h-12"
              />
            </div>
            <span className="text-gray-700 text-sm leading-6 font-semibold">
              {industry?.title}
            </span>
          </a>
        );
      })}
    </div>
  );
};

export default IndustryList;

IndustryList.propTypes = {
  industries: PropTypes.instanceOf(Array).isRequired
};
