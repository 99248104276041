/* eslint-disable indent */
import React from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Tooltip from '../Tooltip/Tooltip';

const ShortlistButton = ({
  onClick,
  loading,
  isShortlisted,
  isInvestorsPage
}) => {
  const { t } = useTranslation();
  return (
    <Tooltip
      text={
        isShortlisted
          ? t('proposalsMacro.shortlisted')
          : t('proposalsMacro.shortlistAdd')
      }
      classNames={
        isInvestorsPage
          ? `whitespace-nowrap ${
              isShortlisted ? 'md:left-1/2 -left-1/4' : 'left-1/2'
            } -translate-x-1/2 transform`
          : ''
      }
    >
      <button
        onClick={onClick}
        type="button"
        className={`
          p-1.5
          bg-gray-200
          hover:bg-gray-300
          w-8
          h-8
          rounded-md
          shortlist-proposal          
          ${isShortlisted ? 'bg-gray-300 text-blue-600' : 'text-gray-700'}
          inline-flex
          items-center
          justify-center
          focus:outline-none
        `}
      >
        {loading ? (
          <svg
            className="animate-spin inline h-3.5 w-3.5 text-gray-600 fill-current"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <title>Shortlist</title>
            <path
              className="opacity-75"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
        ) : (
          <span className="text-xl material-icons">bookmark</span>
        )}
      </button>
    </Tooltip>
  );
};

export default ShortlistButton;

ShortlistButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  isShortlisted: PropTypes.bool,
  isInvestorsPage: PropTypes.bool
};

ShortlistButton.defaultProps = {
  loading: false,
  isShortlisted: false,
  isInvestorsPage: false
};
