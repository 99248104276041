/* eslint-disable no-unused-expressions */
/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import LoadMoreButton from '../../Components/Buttons/LoadMore';
import NoProposalCard from '../../Components/NoProposalCard/NoProposalCard';
import ProposalSummary from '../../Components/ProposalSummary/ProposalSummary';
import {
  fetchProposals,
  sendGetMoreRequestAboutBrokingProposal
} from '../../Util/ApiService';
import { removeEntryFromArray } from '../../Util/data';
import GeneralModal from '../../Components/Modal/GeneralModal';
import { displayToast } from '../../Util/Toast';

const PAGE_SIZE = 6;

const ProposalSummaryTwig = ({
  apiUrl,
  locale,
  loadMoreParameters,
  paginationResponse,
  notFoundMessage,
  proposalPageUri,
  currencyCode,
  preFetchedProposals,
  isFromStaffPicks = false,
  userName,
  email,
  isInvestor,
  getMoreInfoBrokingProposalRequestUri,
  subscriptionTypes
}) => {
  // convert preFetchedresponse string to JSON
  const preRenderedData = paginationResponse
    ? JSON.parse(paginationResponse)
    : null;
  const preSetProposals = preFetchedProposals
    ? JSON.parse(preFetchedProposals)
    : null;

  const { t } = useTranslation();

  const [proposals, setProposals] = useState([]);
  const [showLoadMoreButton, setShowLoadMoreButton] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [message, setMessage] = useState(
    t('staffPicks.canIHaveMoreInformation')
  );
  const [name, setName] = useState(userName);
  const [userEmail, setEmail] = useState(email);
  const [proposalId, setProposalId] = useState(null);

  // eslint-disable-next-line consistent-return
  const renderProposals = () => {
    if (preRenderedData?.results?.length) {
      setProposals(preRenderedData?.results);
      setShowLoadMoreButton(
        PAGE_SIZE * currentPage < preRenderedData?.pagination?.totalResults
      );
      setTotalResults(preRenderedData?.pagination?.totalResults);
    } else if (preSetProposals?.length) {
      setProposals(preSetProposals);
      return setTotalResults(preSetProposals?.length);
    }
  };

  const loadMoreProposals = async (newPageNumber) => {
    setLoading(true);
    const params = loadMoreParameters || {};
    params.pageNumber = newPageNumber;
    params.pageSize = PAGE_SIZE;
    const newProposals = await fetchProposals(apiUrl, params);
    // eslint-disable-next-line no-unused-expressions
    newPageNumber === 1
      ? setProposals(newProposals.results)
      : setProposals([...proposals, ...newProposals.results]);
    setCurrentPage(newPageNumber);
    setLoading(false);
  };

  const removeProposal = (index) => {
    const proposalsArray = [...proposals];
    const newArray = removeEntryFromArray(proposalsArray, index);
    setProposals([...newArray]);
  };

  const openInfoModal = (id) => {
    setProposalId(id);
    setModalOpen(true);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    message && formData.append('message', message);
    name && formData.append('senderName', name);
    userEmail && formData.append('senderEmail', userEmail);
    proposalId && formData.append('proposal', proposalId);

    await sendGetMoreRequestAboutBrokingProposal(
      getMoreInfoBrokingProposalRequestUri,
      formData
    ).then(() => {
      displayToast(t('toast.messageSent'), 'success');
    });
    setModalOpen(false);
  };

  useEffect(() => {
    setShowLoadMoreButton(PAGE_SIZE * currentPage < totalResults);
  }, [currentPage]);

  useEffect(() => {
    if (!proposals.length) {
      renderProposals();
    }
  }, [proposals]);

  return (
    <>
      {proposals?.length ? (
        <div className="w-full grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8 p-4 pt-0 md:max-w-screen-xl mx-auto">
          {preFetchedProposals
            ? proposals.map((proposal, index) => {
                return (
                  <ProposalSummary
                    key={proposal.proposalUri}
                    proposal={proposal}
                    locale={locale}
                    extraClasses="lg:w-1/3 md:w-1/2"
                    currencyCode={currencyCode}
                    isFromStaffPicks={isFromStaffPicks}
                    deleteUri={
                      isFromStaffPicks
                        ? proposal?.proposalUserActions?.blockProposalUri
                        : null
                    }
                    removeProposalFromArray={() => {
                      return removeProposal(index);
                    }}
                    openInfoModal={() => {
                      return openInfoModal(proposal.proposalId);
                    }}
                    isLoggedIn={!!(name && email)}
                    isInvestor={isInvestor}
                    subscriptionTypeCodes={subscriptionTypes}
                  />
                );
              })
            : proposals.map((proposal, index) => {
                return (
                  <ProposalSummary
                    key={proposal.proposalUri}
                    proposal={proposal}
                    locale={locale}
                    extraClasses="lg:w-1/3 md:w-1/2"
                    deleteUri={proposal?.proposalUserActions?.blockProposalUri}
                    shortlistUri={
                      proposal?.proposalUserActions
                        ?.addShortlistedProposalUri ||
                      proposal?.proposalUserActions
                        ?.removeShortlistedProposalUri
                    }
                    removeProposalFromArray={() => {
                      return removeProposal(index);
                    }}
                    currencyCode={currencyCode}
                    isMyListPage
                    isFromStaffPicks={isFromStaffPicks}
                    openInfoModal={() => {
                      return openInfoModal(proposal.proposalId);
                    }}
                    isLoggedIn={!!(name && email)}
                    isInvestor={isInvestor}
                    subscriptionTypeCodes={subscriptionTypes}
                  />
                );
              })}
        </div>
      ) : (
        <div className="w-full flex justify-center">
          <NoProposalCard
            message={notFoundMessage}
            businessProposalUri={proposalPageUri}
          />
        </div>
      )}
      {showLoadMoreButton && (
        <div className="flex items-center justify-center mt-16 mb-24">
          <LoadMoreButton
            title={t('portfolio.button')}
            loading={loading}
            onClick={() => {
              return loadMoreProposals(currentPage + 1);
            }}
          />
        </div>
      )}

      {isModalOpen && (
        <GeneralModal
          title={t('staffPicks.letUsKnowYourEmail')}
          lightHeading
          closeModal={() => {
            return setModalOpen(false);
          }}
        >
          <form onSubmit={handleFormSubmit}>
            <div className="w-full flex flex-col gap-3">
              <input
                type="text"
                placeholder={t('staffPicks.name')}
                value={name}
                onChange={(e) => {
                  return setName(e.target.value);
                }}
                className="p-3 border w-full border-gray-300 placeholder-gray-400 mt-2 rounded focus:outline-none focus:ring-2 ring-blue-400"
                required
              />
              <input
                type="text"
                placeholder={t('staffPicks.email')}
                value={userEmail}
                onChange={(e) => {
                  return setEmail(e.target.value);
                }}
                className="p-3 border w-full border-gray-300 placeholder-gray-400 mt-2 rounded focus:outline-none focus:ring-2 ring-blue-400"
                required
              />
              <label htmlFor="moreInfoMessage">
                <span className="text-gray-800 text-base font-medium">
                  {t('staffPicks.message')}
                </span>
                <textarea
                  id="moreInfoMessage"
                  cols="30"
                  rows="3"
                  value={message}
                  onChange={(e) => {
                    return setMessage(e.target.value);
                  }}
                  placeholder="Message"
                  className="p-3 border w-full border-gray-300 placeholder-gray-400 mt-2 rounded focus:outline-none focus:ring-2 ring-blue-400"
                />
              </label>

              <button
                type="submit"
                className="bg-blue-500 text-white p-3 rounded w-full hover:bg-blue-600 focus:bg-blue-600 mr-2 text-base font-normal"
              >
                {t('staffPicks.send')}
              </button>
            </div>
          </form>
        </GeneralModal>
      )}
    </>
  );
};

export default ProposalSummaryTwig;

ProposalSummaryTwig.propTypes = {
  apiUrl: PropTypes.string,
  getMoreInfoBrokingProposalRequestUri: PropTypes.string,
  locale: PropTypes.string,
  loadMoreParameters: PropTypes.instanceOf(Object),
  paginationResponse: PropTypes.string,
  notFoundMessage: PropTypes.string,
  proposalPageUri: PropTypes.string,
  currencyCode: PropTypes.string,
  preFetchedProposals: PropTypes.string,
  isFromStaffPicks: PropTypes.bool,
  userName: PropTypes.string,
  email: PropTypes.string,
  isInvestor: PropTypes.bool,
  subscriptionTypes: PropTypes.instanceOf(Object)
};

ProposalSummaryTwig.defaultProps = {
  apiUrl: '',
  getMoreInfoBrokingProposalRequestUri: '',
  locale: '',
  loadMoreParameters: null,
  paginationResponse: '',
  notFoundMessage: '',
  proposalPageUri: '',
  currencyCode: '',
  preFetchedProposals: '',
  isFromStaffPicks: false,
  userName: null,
  email: null,
  isInvestor: false,
  subscriptionTypes: null
};
