/* eslint-disable indent */
import React, { useEffect } from 'react';
import * as PropTypes from 'prop-types';
import Markdown from 'markdown-to-jsx';
import useOnclickOutside from 'react-cool-onclickoutside';

const GeneralModal = ({
  children,
  closeModal,
  description,
  innerCloseIcon,
  lightHeading,
  title
}) => {
  const modalContent = useOnclickOutside(() => {
    closeModal();
  });

  useEffect(() => {
    // eslint-disable-next-line no-undef
    document.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        closeModal();
      }
    });
  }, []);

  return (
    <div
      tabIndex="-1"
      className={`overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-full md:h-screen justify-center items-center flex ${
        children ? 'bg-white' : 'bg-gray-50 bg-opacity-75'
      }`}
      role="dialog"
    >
      {!innerCloseIcon && (
        <button
          onClick={closeModal}
          type="button"
          className="
          absolute
          top-4
          right-4
          inline-flex
          items-center
          justify-center
          w-12
          h-12
          bg-gray-200
          rounded-full
        "
        >
          <span className="material-icons text-gray-800">close</span>
        </button>
      )}
      <div
        ref={modalContent}
        className={`
          md:w-full
          w-11/12
          max-w-xl
          h-auto
          m-auto
          absolute
          md:top-16
          top-20
          bg-white
          rounded-lg
          flex
          flex-col
          ${innerCloseIcon ? 'p-6 gap-6' : 'md:p-10 p-8 gap-2.5'}
          ${children ? 'shadow border border-blue-100' : 'shadow-4xl'}
        `}
      >
        <div className="w-full flex items-center justify-between gap-3">
          <h3
            className={`${
              innerCloseIcon
                ? 'text-gray-800 text-2xl font-semibold leading-8'
                : lightHeading
                ? 'text-gray-800 text-center text-2xl font-medium mb-0'
                : 'text-gray-700 text-3xl leading-8 font-semibold'
            }`}
          >
            {title}
          </h3>
          {innerCloseIcon && (
            <button
              onClick={closeModal}
              type="button"
              className="inline-flex items-center justify-center w-8 h-8 bg-gray-200 rounded-md"
            >
              <span className="material-icons text-gray-800">close</span>
            </button>
          )}
        </div>
        {description && (
          <Markdown className="text-gray-700 text-lg modal-markdown">
            {description}
          </Markdown>
        )}
        {children && children}
      </div>
    </div>
  );
};

export default GeneralModal;

GeneralModal.propTypes = {
  children: PropTypes.element,
  closeModal: PropTypes.func.isRequired,
  description: PropTypes.string,
  innerCloseIcon: PropTypes.bool,
  lightHeading: PropTypes.bool,
  title: PropTypes.string.isRequired
};

GeneralModal.defaultProps = {
  children: null,
  description: null,
  innerCloseIcon: false,
  lightHeading: false
};
