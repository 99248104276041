import ReactOnRails from 'react-on-rails';
import ProposalSummaryTwig from '../src/React/Pages/ProposalSummaryTwig/ProposalSummaryTwig';
import InvestorCardTwig from "../src/React/Pages/InvestorCardTwig/InvestorCardTwig";
import StatesSection from "../src/React/Pages/States/States";
import IndustryList from "../src/React/Pages/IndustryList/IndustryList";

ReactOnRails.register({ IndustryList });
ReactOnRails.register({ InvestorCardTwig });
ReactOnRails.register({ ProposalSummaryTwig });
ReactOnRails.register({ StatesSection });

