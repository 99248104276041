import React from 'react';
import * as PropTypes from 'prop-types';

const TooltipAlt = ({ style, colorClass, textColor, label }) => {
  // Note: colorClass and textColor should have Tailwind color classes. e.g: red-600, blue-500, etc.
  return (
    <div
      className={`
        absolute
        whitespace-nowrap
        px-4
        py-1.5
        text-xs
        ${colorClass ? `bg-${colorClass}` : 'bg-green-400'}
        ${textColor ? `text-${textColor}` : 'text-white'}
        rounded
        bottom-4
        hidden
        group-hover:block
        transform
        transition
        transition-all
        duration-300
        -translate-x-1/2
        text-center
        z-10
      `}
      style={style || null}
    >
      {label}
      <span
        className={`
          material-icons
          ${colorClass ? `text-${colorClass}` : 'text-green-400'}
          absolute
          left-1/2
          transform
          -translate-x-1/2
          -bottom-3.5
        `}
      >
        arrow_drop_down
      </span>
    </div>
  );
};

export default TooltipAlt;

TooltipAlt.propTypes = {
  style: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  colorClass: PropTypes.string,
  textColor: PropTypes.string,
  label: PropTypes.string.isRequired
};

TooltipAlt.defaultProps = {
  style: null,
  colorClass: null,
  textColor: null
};
