import React from 'react';
import * as PropTypes from 'prop-types';

const Loader = ({ customColor, height, title, width }) => {
  return (
    <svg
      className={`animate-spin inline ${height || 'h-3.5'} ${
        width || 'w-3.5'
      } fill-current ${customColor || 'text-white'}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <title>{title}</title>
      <path
        className="opacity-75"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      />
    </svg>
  );
};

export default Loader;

Loader.propTypes = {
  customColor: PropTypes.string,
  height: PropTypes.string,
  title: PropTypes.string.isRequired,
  width: PropTypes.string
};

Loader.defaultProps = {
  customColor: null,
  height: null,
  width: null
};
