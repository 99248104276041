import React from 'react';
import * as PropTypes from 'prop-types';
import i18next from '../../Util/i18n';

const LoadMoreButton = ({ title, onClick, loading }) => {
  return (
    <button
      type="button"
      className="loader inline-flex bg-gray-200 text-gray-800 px-6 py-3 text-base rounded-md w-auto hover:bg-gray-300 focus:bg-gray-200"
      onClick={onClick}
      disabled={loading}
    >
      {loading ? i18next.t('investorCard.loading') : title}
    </button>
  );
};

export default LoadMoreButton;

LoadMoreButton.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  loading: PropTypes.bool
};

LoadMoreButton.defaultProps = {
  loading: false,
  onClick: () => {}
};
