import React from 'react';
import * as PropTypes from 'prop-types';

const Tooltip = ({ children, text, classNames }) => {
  return (
    <div className="has-tooltip">
      {text && (
        <span
          className={`tooltip rounded shadow-lg py-1 px-2 bg-gray-800 text-white text-sm -mt-9 ${
            classNames || ''
          }`}
        >
          {text}
        </span>
      )}
      {children}
    </div>
  );
};

export default Tooltip;

Tooltip.propTypes = {
  children: PropTypes.element.isRequired,
  text: PropTypes.string,
  classNames: PropTypes.string
};

Tooltip.defaultProps = {
  text: '',
  classNames: null
};
