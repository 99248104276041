import React from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const NoProposalCard = ({ message, businessProposalUri }) => {
  const { t } = useTranslation();

  return (
    <div className="md:max-w-sm flex-grow rounded-md border-2 border-dashed bg-slate-50 py-32 m-3">
      <div className="px-16 text-center">
        <p className="text-xl italic font-medium text-slate-300">
          {message}
          <br />
          <br />
          {t('myList.message.click')}
        </p>
        <img
          src="/images/small/curve-arrow-28X84.png"
          alt="curved arrow"
          className="py-3"
        />
        <div className="flex justify-center">
          <a
            href={businessProposalUri}
            className="
              bg-blue-400
              cursor-pointer
              rounded-full
              w-28
              h-28
              text-white
              text-7xl
              flex
              justify-center
              items-center
            "
          >
            &#65291;
          </a>
        </div>
      </div>
    </div>
  );
};

export default NoProposalCard;

NoProposalCard.propTypes = {
  message: PropTypes.string,
  businessProposalUri: PropTypes.string
};

NoProposalCard.defaultProps = {
  message: null,
  businessProposalUri: null
};
