/* eslint-disable no-undef */
/* eslint-disable import/no-webpack-loader-syntax */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// proposal card translations
import deYaml from 'js-yaml-loader!../../../translations/affiliate/portfolio/messages.de.yaml';
import enYaml from 'js-yaml-loader!../../../translations/affiliate/portfolio/messages.en.yaml';
import esYaml from 'js-yaml-loader!../../../translations/affiliate/portfolio/messages.es.yaml';
import frYaml from 'js-yaml-loader!../../../translations/affiliate/portfolio/messages.fr.yaml';

// investor card translations
import invDeYaml from 'js-yaml-loader!../../../translations/macros/investor-card/messages.de.yaml';
import invEnYaml from 'js-yaml-loader!../../../translations/macros/investor-card/messages.en.yaml';
import invEsYaml from 'js-yaml-loader!../../../translations/macros/investor-card/messages.es.yaml';
import invFrYaml from 'js-yaml-loader!../../../translations/macros/investor-card/messages.fr.yaml';

// inbox translations
import inboxDeYaml from 'js-yaml-loader!../../../translations/auth/inbox/messages.de.yaml';
import inboxEnYaml from 'js-yaml-loader!../../../translations/auth/inbox/messages.en.yaml';
import inboxEsYaml from 'js-yaml-loader!../../../translations/auth/inbox/messages.es.yaml';
import inboxFrYaml from 'js-yaml-loader!../../../translations/auth/inbox/messages.fr.yaml';
import cancellationSurveyEnYaml from 'js-yaml-loader!../../../translations/subscription/cancellation-survey/messages.en.yaml';
import cancellationSurveyDeYaml from 'js-yaml-loader!../../../translations/subscription/cancellation-survey/messages.de.yaml';
import cancellationSurveyEsYaml from 'js-yaml-loader!../../../translations/subscription/cancellation-survey/messages.es.yaml';
import cancellationSurveyFrYaml from 'js-yaml-loader!../../../translations/subscription/cancellation-survey/messages.fr.yaml';
// my investors translations
import myInvDeYaml from 'js-yaml-loader!../../../translations/entrepreneur/my-investors/messages.de.yaml';
import myInvEnYaml from 'js-yaml-loader!../../../translations/entrepreneur/my-investors/messages.en.yaml';
import myInvEsYaml from 'js-yaml-loader!../../../translations/entrepreneur/my-investors/messages.es.yaml';
import myInvFrYaml from 'js-yaml-loader!../../../translations/entrepreneur/my-investors/messages.fr.yaml';

// my list translations
import myListDeYaml from 'js-yaml-loader!../../../translations/investor/my-list/messages.de.yaml';
import myListEnYaml from 'js-yaml-loader!../../../translations/investor/my-list/messages.en.yaml';
import myListEsYaml from 'js-yaml-loader!../../../translations/investor/my-list/messages.es.yaml';
import myListFrYaml from 'js-yaml-loader!../../../translations/investor/my-list/messages.fr.yaml';

// find investors translations
import findInvDeYaml from 'js-yaml-loader!../../../translations/entrepreneur/find-investors/messages.de.yaml';
import findInvEnYaml from 'js-yaml-loader!../../../translations/entrepreneur/find-investors/messages.en.yaml';
import findInvEsYaml from 'js-yaml-loader!../../../translations/entrepreneur/find-investors/messages.es.yaml';
import findInvFrYaml from 'js-yaml-loader!../../../translations/entrepreneur/find-investors/messages.fr.yaml';

// business proposal translations
import businessProposalDeYaml from 'js-yaml-loader!../../../translations/proposal/business-proposal/messages.de.yaml';
import businessProposalEnYaml from 'js-yaml-loader!../../../translations/proposal/business-proposal/messages.en.yaml';
import businessProposalEsYaml from 'js-yaml-loader!../../../translations/proposal/business-proposal/messages.es.yaml';
import businessProposalFrYaml from 'js-yaml-loader!../../../translations/proposal/business-proposal/messages.fr.yaml';

import commonLocationsDeYaml from 'js-yaml-loader!../../../translations/common/locations/messages.de.yaml';
import commonLocationsEnYaml from 'js-yaml-loader!../../../translations/common/locations/messages.en.yaml';
import commonLocationsEsYaml from 'js-yaml-loader!../../../translations/common/locations/messages.es.yaml';
import commonLocationsFrYaml from 'js-yaml-loader!../../../translations/common/locations/messages.fr.yaml';

// nudge modal translations
import nudgeDeYaml from 'js-yaml-loader!../../../translations/macros/nudge/messages.de.yaml';
import nudgeEnYaml from 'js-yaml-loader!../../../translations/macros/nudge/messages.en.yaml';
import nudgeEsYaml from 'js-yaml-loader!../../../translations/macros/nudge/messages.es.yaml';
import nudgeFrYaml from 'js-yaml-loader!../../../translations/macros/nudge/messages.fr.yaml';

// investor prompt translations
import invPromptDeYaml from 'js-yaml-loader!../../../translations/investor/search/messages.de.yaml';
import invPromptEnYaml from 'js-yaml-loader!../../../translations/investor/search/messages.en.yaml';
import invPromptEsYaml from 'js-yaml-loader!../../../translations/investor/search/messages.es.yaml';
import invPromptFrYaml from 'js-yaml-loader!../../../translations/investor/search/messages.fr.yaml';

// investor dashboard translations
import investorDashboardDeYaml from 'js-yaml-loader!../../../translations/investor/dashboard/messages.de.yaml';
import investorDashboardEnYaml from 'js-yaml-loader!../../../translations/investor/dashboard/messages.en.yaml';
import investorDashboardEsYaml from 'js-yaml-loader!../../../translations/investor/dashboard/messages.es.yaml';
import investorDashboardFrYaml from 'js-yaml-loader!../../../translations/investor/dashboard/messages.fr.yaml';

// investor dashboard translations
import entrepreneurDashboardDeYaml from 'js-yaml-loader!../../../translations/entrepreneur/dashboard/messages.de.yaml';
import entrepreneurDashboardEnYaml from 'js-yaml-loader!../../../translations/entrepreneur/dashboard/messages.en.yaml';
import entrepreneurDashboardEsYaml from 'js-yaml-loader!../../../translations/entrepreneur/dashboard/messages.es.yaml';
import entrepreneurDashboardFrYaml from 'js-yaml-loader!../../../translations/entrepreneur/dashboard/messages.fr.yaml';

// newsfeed translations
import newsfeedDeYaml from 'js-yaml-loader!../../../translations/newsfeed/messages.de.yaml';
import newsfeedEnYaml from 'js-yaml-loader!../../../translations/newsfeed/messages.en.yaml';
import newsfeedEsYaml from 'js-yaml-loader!../../../translations/newsfeed/messages.es.yaml';
import newsfeedFrYaml from 'js-yaml-loader!../../../translations/newsfeed/messages.fr.yaml';

// staff picks translations
import staffPicksDeYaml from 'js-yaml-loader!../../../translations/proposal/staff-picks/messages.de.yaml';
import staffPicksEnYaml from 'js-yaml-loader!../../../translations/proposal/staff-picks/messages.en.yaml';
import staffPicksEsYaml from 'js-yaml-loader!../../../translations/proposal/staff-picks/messages.es.yaml';
import staffPicksFrYaml from 'js-yaml-loader!../../../translations/proposal/staff-picks/messages.fr.yaml';

// toast translations
import toastDeYaml from 'js-yaml-loader!../../../translations/common/toast/messages.de.yaml';
import toastEnYaml from 'js-yaml-loader!../../../translations/common/toast/messages.en.yaml';
import toastEsYaml from 'js-yaml-loader!../../../translations/common/toast/messages.es.yaml';
import toastFrYaml from 'js-yaml-loader!../../../translations/common/toast/messages.fr.yaml';

// explore page translations
import exploreDeYaml from 'js-yaml-loader!../../../translations/explore/messages.de.yaml';
import exploreEnYaml from 'js-yaml-loader!../../../translations/explore/messages.en.yaml';
import exploreEsYaml from 'js-yaml-loader!../../../translations/explore/messages.es.yaml';
import exploreFrYaml from 'js-yaml-loader!../../../translations/explore/messages.fr.yaml';

// homepage translations
import homepageDeYaml from 'js-yaml-loader!../../../translations/homepage/messages.de.yaml';
import homepageEnYaml from 'js-yaml-loader!../../../translations/homepage/messages.en.yaml';
import homepageEsYaml from 'js-yaml-loader!../../../translations/homepage/messages.es.yaml';
import homepageFrYaml from 'js-yaml-loader!../../../translations/homepage/messages.fr.yaml';

// notification page translations
import notificationDeYaml from 'js-yaml-loader!../../../translations/notification/messages.de.yaml';
import notificationEnYaml from 'js-yaml-loader!../../../translations/notification/messages.en.yaml';
import notificationEsYaml from 'js-yaml-loader!../../../translations/notification/messages.es.yaml';
import notificationFrYaml from 'js-yaml-loader!../../../translations/notification/messages.fr.yaml';

import testimonialsDeYaml from 'js-yaml-loader!../../../translations/content/testimonials/messages.de.yaml';
import testimonialsEnYaml from 'js-yaml-loader!../../../translations/content/testimonials/messages.en.yaml';
import testimonialsEsYaml from 'js-yaml-loader!../../../translations/content/testimonials/messages.es.yaml';
import testimonialsFrYaml from 'js-yaml-loader!../../../translations/content/testimonials/messages.fr.yaml';

// detect lang attribute
if (typeof window !== 'undefined' && typeof document !== 'undefined') {
  const currentLang = document.documentElement.lang;

  switch (currentLang) {
    case 'sp':
      document.documentElement.lang = 'es';
      break;
    case 'ge':
      document.documentElement.lang = 'de';
      break;
    default:
      document.documentElement.lang = currentLang;
  }
}

const options = {
  // order and from where user language should be detected
  order: ['htmlTag', 'navigator', 'path'],

  // keys or params to lookup language from
  lookupQuerystring: 'lng',
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  // cache user language on
  caches: ['localStorage', 'cookie']
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection: options,
    resources: {
      en: {
        translation: {
          ...enYaml,
          ...invEnYaml,
          ...findInvEnYaml,
          ...commonLocationsEnYaml,
          ...inboxEnYaml,
          ...myInvEnYaml,
          ...businessProposalEnYaml,
          ...myListEnYaml,
          ...nudgeEnYaml,
          ...invPromptEnYaml,
          ...investorDashboardEnYaml,
          ...entrepreneurDashboardEnYaml,
          ...newsfeedEnYaml,
          ...staffPicksEnYaml,
          ...toastEnYaml,
          ...exploreEnYaml,
          ...cancellationSurveyEnYaml,
          ...homepageEnYaml,
          ...notificationEnYaml,
          ...testimonialsEnYaml,
          currency: '{{val, currency}}'
        }
      },
      fr: {
        translation: {
          ...frYaml,
          ...invFrYaml,
          ...findInvFrYaml,
          ...commonLocationsFrYaml,
          ...inboxFrYaml,
          ...myInvFrYaml,
          ...businessProposalFrYaml,
          ...myListFrYaml,
          ...nudgeFrYaml,
          ...invPromptFrYaml,
          ...investorDashboardFrYaml,
          ...entrepreneurDashboardFrYaml,
          ...newsfeedFrYaml,
          ...staffPicksFrYaml,
          ...toastFrYaml,
          ...exploreFrYaml,
          ...cancellationSurveyFrYaml,
          ...homepageFrYaml,
          ...notificationFrYaml,
          ...testimonialsFrYaml,
          currency: '{{val, currency}}'
        }
      },
      de: {
        translation: {
          ...deYaml,
          ...invDeYaml,
          ...findInvDeYaml,
          ...commonLocationsDeYaml,
          ...inboxDeYaml,
          ...myInvDeYaml,
          ...businessProposalDeYaml,
          ...myListDeYaml,
          ...nudgeDeYaml,
          ...invPromptDeYaml,
          ...investorDashboardDeYaml,
          ...entrepreneurDashboardDeYaml,
          ...newsfeedDeYaml,
          ...staffPicksDeYaml,
          ...toastDeYaml,
          ...exploreDeYaml,
          ...cancellationSurveyDeYaml,
          ...homepageDeYaml,
          ...notificationDeYaml,
          ...testimonialsDeYaml,
          currency: '{{val, currency}}'
        }
      },
      es: {
        translation: {
          ...esYaml,
          ...invEsYaml,
          ...findInvEsYaml,
          ...commonLocationsEsYaml,
          ...inboxEsYaml,
          ...myInvEsYaml,
          ...businessProposalEsYaml,
          ...myListEsYaml,
          ...nudgeEsYaml,
          ...invPromptEsYaml,
          ...investorDashboardEsYaml,
          ...entrepreneurDashboardEsYaml,
          ...newsfeedEsYaml,
          ...staffPicksEsYaml,
          ...toastEsYaml,
          ...exploreEsYaml,
          ...cancellationSurveyEsYaml,
          ...homepageEsYaml,
          ...notificationEsYaml,
          ...testimonialsEsYaml,
          currency: '{{val, currency}}'
        }
      }
    },
    // lng: 'en', // lng: (window && window.locale) || 'en', we can't use window for SSR - need to pass in from twig controller
    fallbackLng: 'en'
  });

export default i18n;
