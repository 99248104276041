import React from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Tooltip from '../Tooltip/Tooltip';

const DeleteButton = ({ onClick, deleting }) => {
  const { t } = useTranslation();
  return (
    <Tooltip text={t('proposalsMacro.notInterested')}>
      <button
        type="button"
        onClick={onClick}
        className="
          p-1.5
          bg-gray-200
          hover:bg-gray-300
          w-8
          h-8
          rounded-md
          shortlist-proposal          
          text-gray-700
          inline-flex
          items-center
          justify-center
          focus:outline-none
        "
      >
        {deleting ? (
          <svg
            className="animate-spin inline h-3.5 w-3.5 text-gray-600 fill-current"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <title>Delete</title>
            <path
              className="opacity-75"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
        ) : (
          <span className="text-xl material-icons">delete</span>
        )}
      </button>
    </Tooltip>
  );
};

export default DeleteButton;

DeleteButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  deleting: PropTypes.bool
};

DeleteButton.defaultProps = {
  deleting: false
};
