var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import '../../Util/i18n';
import { useTranslation } from 'react-i18next';
import { formatInvestorName, getLastCharacter, renderUserAvatar, roundUpInvestmentFigure } from '../../Util/data';
import { shortlistInvestor, unShortlistInvestor } from '../../Util/ApiService';
import Loader from '../Loader/Loader';
import ShortlistButton from '../Buttons/Shortlist';
import Tooltip from '../Tooltip/Tooltip';
const InvestorCard = ({ investor, locale = '', nudgeUri = '', shortlistUri = '', openNudgeModal, isLoggedIn = false, alreadyNudged = false, currencyCode = '' }) => {
    var _a, _b, _c, _d, _e, _f;
    const { t } = useTranslation();
    const [shortlistLoading, setShortlistLoading] = useState(false);
    const [nudging] = useState(false);
    const [isShortlisted, setIsShortlisted] = useState((_a = investor.investorUserActions) === null || _a === void 0 ? void 0 : _a.alreadyShortlisted);
    const [selectedNudgeUri, setSelectedNudgeUri] = useState(null);
    const [selectedUserNudgeUri, setSelectedUserNudgeUri] = useState(null);
    const [isAlreadyNudged, setAlreadyNudged] = useState(!((_b = investor.investorUserActions) === null || _b === void 0 ? void 0 : _b.allowToNudge));
    const [isClient, setIsClient] = useState(false);
    useEffect(() => {
        setIsClient(true);
    }, []);
    const shortList = (url) => __awaiter(void 0, void 0, void 0, function* () {
        setShortlistLoading(true);
        if (isShortlisted) {
            yield unShortlistInvestor(url);
        }
        else {
            yield shortlistInvestor(url);
        }
        setIsShortlisted(!isShortlisted);
        setShortlistLoading(false);
    });
    const nudge = () => {
        openNudgeModal &&
            openNudgeModal(nudgeUri, t('nudgeModal.sendMessage'), 'nudge');
        setSelectedNudgeUri(nudgeUri);
    };
    const goToInbox = () => {
        var _a, _b;
        ((_a = investor.investorUserActions) === null || _a === void 0 ? void 0 : _a.inboxConnectionUri) &&
            window.open((_b = investor.investorUserActions) === null || _b === void 0 ? void 0 : _b.inboxConnectionUri, '_blank');
    };
    useEffect(() => {
        var _a;
        if (alreadyNudged && selectedUserNudgeUri === selectedNudgeUri) {
            // @ts-ignore FIXME assigning value which should not be assigned
            (_a = investor.investorUserActions) === null || _a === void 0 ? void 0 : _a.alreadyNudged = true;
            setAlreadyNudged(true);
        }
        setSelectedUserNudgeUri(nudgeUri);
    }, [alreadyNudged]);
    return (React.createElement("div", { className: "w-full md:max-w-md md:min-w-sm mx-auto flex flex-col py-6 px-4" },
        React.createElement("div", { className: "bg-white rounded-lg h-full flex flex-col gap-4 shadow-3xl p-6" },
            React.createElement("div", { className: "w-full flex items-center gap-4 relative" },
                investor.logoUrl ? (React.createElement("img", { className: "w-20 h-20 rounded-full object-center object-cover flex-shrink-0", src: investor.logoUrl, alt: (_c = investor.fullName) !== null && _c !== void 0 ? _c : '' })) : isClient ? (renderUserAvatar(investor.fullName, 'w-20 h-20 flex-shrink-0', `bg-${getLastCharacter(investor.investorId)}`)) : null,
                React.createElement("div", { className: "flex flex-col" },
                    React.createElement("h3", { className: "text-gray-800 text-xl font-semibold leading-8" }, formatInvestorName(investor.fullName)),
                    React.createElement("p", { className: "text-sm text-gray-700 font-normal flex items-center gap-1" },
                        React.createElement("span", { className: "material-icons text-gray-500 -mt-0.5" }, "location_on"),
                        investor.city ? `${investor.city}, ` : '',
                        investor.countryName)),
                shortlistUri && (React.createElement("div", { className: "absolute top-0 right-0" },
                    React.createElement(ShortlistButton, { onClick: () => {
                            return shortList(shortlistUri);
                        }, loading: shortlistLoading, isShortlisted: isShortlisted, isInvestorsPage: true })))),
            React.createElement("div", { className: "flex flex-col gap-4" },
                React.createElement("div", { className: "flex flex-col gap-1" },
                    React.createElement("h4", { className: "text-gray-800 text-base font-semibold leading-6" }, t('findInvestors.investmentRange')),
                    React.createElement("p", { className: "text-gray-800 leading-8 text-xl font-semibold" },
                        t('currency', {
                            val: roundUpInvestmentFigure(investor.minInvestment, investor.exchangeRateToSiteCurrency),
                            formatParams: {
                                val: {
                                    currency: currencyCode,
                                    locale: locale.replace(/_/g, '-'),
                                    maximumFractionDigits: 0
                                }
                            }
                        }),
                        "\u00A0-\u00A0",
                        t('currency', {
                            val: roundUpInvestmentFigure(investor.maxInvestment, investor.exchangeRateToSiteCurrency),
                            formatParams: {
                                val: {
                                    currency: currencyCode,
                                    locale: locale.replace(/_/g, '-'),
                                    maximumFractionDigits: 0
                                }
                            }
                        }))),
                React.createElement("p", { className: "line-clamp-3 text-sm text-gray-700 leading-6 flex-1" }, investor.background && investor.background.length > 250
                    ? `${investor.background.slice(0, 250)} ...`
                    : investor.background),
                React.createElement("div", { className: "flex flex-col gap-1" },
                    React.createElement("h5", { className: "text-base leading-6 font-semibold text-gray-800" }, t('investorCard.areasOfExpertise')),
                    React.createElement("p", { className: "line-clamp-3 text-sm text-gray-700 leading-6 flex-1" }, investor.expertise && investor.expertise.length > 250
                        ? `${investor.expertise.slice(0, 250)} ...`
                        : investor.expertise))),
            React.createElement("div", { className: "flex flex-wrap items-center gap-2 mt-auto" },
                isLoggedIn && (React.createElement(Tooltip, { text: ((_d = investor.investorUserActions) === null || _d === void 0 ? void 0 : _d.inboxConnectionUri)
                        ? t('investorCard.viewThread')
                        : !isAlreadyNudged
                            ? t('investorCard.nudgeInvestor')
                            : null },
                    React.createElement("button", { type: "button", className: `
                  bg-blue-600
                  hover:bg-blue-500
                  text-white
                  rounded-md
                  py-1
                  px-3
                  font-semibold
                  text-sm
                  leading-6
                  whitespace-nowrap
                `, onClick: () => {
                            isAlreadyNudged ? goToInbox() : nudge();
                        }, disabled: isAlreadyNudged &&
                            !((_e = investor.investorUserActions) === null || _e === void 0 ? void 0 : _e.inboxConnectionUri) }, nudging ? (React.createElement(Loader, { title: "Loading" })) : ((_f = investor.investorUserActions) === null || _f === void 0 ? void 0 : _f.inboxConnectionUri) ? (t('investorCard.message')) : isAlreadyNudged ? (t('investorCard.alreadyNudged')) : (t('investorCard.nudge'))))),
                React.createElement("a", { href: investor.detailsUrl, target: "_blank", className: `
              ${!isLoggedIn
                        ? 'bg-blue-600 hover:bg-blue-500 text-white'
                        : 'bg-gray-200 hover:bg-gray-300 text-gray-800'}
              rounded-md
              py-1
              px-3
              font-semibold
              text-sm
              leading-6
              whitespace-nowrap
            `, rel: "noreferrer" }, t('investorCard.moreDetails'))))));
};
export default InvestorCard;
