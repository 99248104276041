import React from 'react';
import * as PropTypes from 'prop-types';

const Doc = ({ classNames, fileName }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25 33"
      fill="none"
      className={classNames}
    >
      <title>{fileName}</title>
      <path
        d="M0 3C0 1.34315 1.34441 0 3.00127 0C8.73203 0 13.1523 0 18.383 0L24.5106 6.94444V29.6389C24.5106 31.2957 23.1675 32.6389 21.5106 32.6389H3C1.34315 32.6389 0 31.2957 0 29.6389V3Z"
        fill="#2563EB"
      />
      <path d="M18.3828 6.94444V0L24.5105 6.94444H18.3828Z" fill="#1E40AF" />
      <path
        d="M4.90234 12.2396H18.6283V13.2595H4.90234V12.2396Z"
        fill="white"
      />
      <path
        d="M4.90234 16.3194H18.6283V17.3394H4.90234V16.3194Z"
        fill="white"
      />
      <path
        d="M4.90234 20.3993H14.7066V21.4193H4.90234V20.3993Z"
        fill="white"
      />
    </svg>
  );
};

export default Doc;

Doc.propTypes = {
  classNames: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired
};
