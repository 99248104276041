import React from 'react';
import * as PropTypes from 'prop-types';

const Xls = ({ classNames, fileName }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25 32"
      fill="none"
      className={classNames}
    >
      <title>{fileName}</title>
      <path
        d="M0 3C0 1.34315 1.34441 0 3.00127 0C8.73203 0 13.1523 0 18.383 0L24.5106 6.80851V29C24.5106 30.6569 23.1675 32 21.5106 32H3C1.34315 32 0 30.6569 0 29V3Z"
        fill="#059669"
      />
      <path d="M18.3828 6.80851V0L24.5105 6.80851H18.3828Z" fill="#065F46" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.6283 12H5.88277V21H18.6283V12ZM4.90234 11V22H19.6087V11H4.90234Z"
        fill="white"
      />
      <path d="M4.90234 17H19.6087V16H4.90234V17Z" fill="white" />
      <path d="M11.7656 11H12.746V22H11.7656V11Z" fill="white" />
    </svg>
  );
};

export default Xls;

Xls.propTypes = {
  classNames: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired
};
